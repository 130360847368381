body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


a {
  color: #c3002f;
}

button, button:active, button:focus,
th {
  outline: none;
}

.table {
  background-color: #FFF;
}

.table thead th {
  cursor: pointer;
  vertical-align: middle;
}

th.sortable label{
  margin-bottom: 0;
}

.date-filter {
  display: inline-block;
}

.table thead th input {
  margin-left: 5px;
}

.table-bordered td, .table-bordered th {
  border-right: 0;
  border-left: 0;
}


.RaLayout-content-19 {
  margin-top: 15px;
}

.RaLayout-content-19 .container {
  /*background-color: #FFF;
  padding: 10px 15px;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);*/
}

.breadcrumb {
  background-color: transparent;
}

.white-container {
  background-color: #FFF;
}

.container h1 {
  font-size: 1.5rem;
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.container h1:after {
  content: "";
  width: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  background-color: #c3002f;
}

.MuiSvgIcon-root{
  fill: #c3002f !important;
}

.MuiButton-containedPrimary {
  background-color: #FFF !important;
  color: #c3002f !important;
}

.MuiButton-textPrimary {
  color: #c3002f !important;
}

.react-bootstrap-table {
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}

#pageDropDown {
  background-color: transparent;
  color: #000;
}

.dropdown-menu {
  color: #000;
}

.page-link, .page-link:focus, .page-link:active, .page-link:hover {
  color: #000;
}
.page-item.active .page-link {
  background-color: #000;
  border-color: #000;
  color: #FFF;
}

.card {
  background-color: #f3f3f3;
  display: inline-block;
  margin: 0 auto;
  text-align: left;
  min-width: 450px;
  padding: 35px 25px 8px 25px;
}

.card label, p label {
  color: #c3002f;
  font-weight: bold;
}

.card-full-width {
  min-width: 100%;
}

.custom-icon {
  display: block;
  height: 30px;
  margin: 0 auto;
  width: 30px;
}

.edit-icon {
  background: url('./assets/images/edit.png') left / 20px no-repeat;
  display: inline-block !important;
  padding-left: 20px !important;
}

.delete-icon {
  background: url('./assets/images/delete.png') left / 20px no-repeat;
  display: inline-block !important;
  padding-left: 22px !important;
}

.tick-icon {
  background: url('./assets/images/tick.png') left / 20px no-repeat;
  display: inline-block !important;
  padding-left: 22px !important;
}

.hide {
  display: none;
}
